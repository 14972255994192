<template>
	<div class="formStep">
		<div class="container">
			<div class="title">
				{{$t('form.exp.EH2')}}
			</div>
			<form-input
				:placeholder="$t('form.exp.t15')"
				@change="onFieldChange"
				propname="prodName"
				:validate="$v.prodName"
				:showError="showError"
				:prevalue="prodName"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t16')"
				@change="onFieldChange"
				propname="manufacturer"
				:validate="$v.manufacturer"
				:showError="showError"
				:prevalue="manufacturer"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t17')"
				@change="onFieldChange"
				propname="prodNo"
				:validate="$v.prodNo"
				:showError="showError"
				:prevalue="prodNo"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t18')"
				@change="onFieldChange"
				propname="testimony"
				:validate="$v.testimony"
				:showError="showError"
				:prevalue="testimony"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t19')"
				@change="onFieldChange"
				propname="dose"
				:validate="$v.dose"
				:showError="showError"
				:prevalue="dose"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t20')"
				@change="onFieldChange"
				propname="multiplicity"
				:validate="$v.multiplicity"
				:showError="showError"
				:prevalue="multiplicity"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t21')"
				@change="onFieldChange"
				propname="drugAdministration"
				:validate="$v.drugAdministration"
				:showError="showError"
				:prevalue="drugAdministration"
			></form-input>
			<div class="centerWideWrp">
				<label class="label">{{$t('form.exp.t22')}}</label>
				<date-picker-field
					:placeholder="$t('form.exp.t23')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.terapiaStart"
					propname="terapiaStart"
					:prevalue="terapiaStart"
				></date-picker-field>
				<checkbox
					class="nomargin"
					:label="$t('form.exp.t24')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.terapiaStartUndefined"
					propname="terapiaStartUndefined"
					:prevalue="terapiaStartUndefined"
				></checkbox>
			</div>
			<div class="centerWideWrp">
				<label class="label">{{$t('form.exp.t25')}}</label>
				<date-picker-field
					:placeholder="$t('form.exp.t26')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.terapiaEnd"
					propname="terapiaEnd"
					:prevalue="terapiaEnd"
				></date-picker-field>
				<checkbox
					class="nomargin"
					:label="$t('form.exp.t27')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.terapiaEndUndefined"
					propname="terapiaEndUndefined"
					:prevalue="terapiaEndUndefined"
				></checkbox>
			</div>
			<div class="formBtns">
				<button class="back" @click="$emit('back')">
					<span class="ic-icarrow-left"></span>
					<div>{{ $t("formActions.back") }}</div>
				</button>
				<button class="next" @click="onNext">
					<div>{{ $t("formActions.next") }}</div>
					<span class="ic-icarrow-right"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import DatePickerField from "../../Inputs/DatePickerField.vue";
import FormInput from "../../Inputs/FormInput.vue";
import { dateValidator } from "@/CustomValidators.js";
import Checkbox from "../../Inputs/Checkbox.vue";
export default {
	components: { FormInput, DatePickerField, Checkbox },
	props: {
		formdata: {
			type: Object,
			default: null,
		},
	},
	name: "ExpertFormStep2",
	data() {
		return {
			showError: false,

			prodName: "",
			manufacturer: "",
			prodNo: "",
			testimony: "",
			dose: "",
			multiplicity: "",
			drugAdministration: "",

			terapiaStart: "",
			terapiaStartUndefined: false,
			terapiaEnd: "",
			terapiaEndUndefined: false,
		};
	},
	methods: {
		onNext() {
			this.showError = true;
			if (!this.$v.$invalid) {
				this.$emit("next", {
					prodName: this.prodName,
					manufacturer: this.manufacturer,
					prodNo: this.prodNo,
					testimony: this.testimony,
					dose: this.dose,
					multiplicity: this.multiplicity,
					drugAdministration: this.drugAdministration,
					terapiaStart: this.terapiaStart,
					terapiaStartUndefined: this.terapiaStartUndefined,
					terapiaEnd: this.terapiaEnd,
					terapiaEndUndefined: this.terapiaEndUndefined,
				});
			}
		},
	},
	mounted () {
		for (const key in this.$data) {
			if (Object.hasOwnProperty.call(this.$data, key)) {
				if (Object.hasOwnProperty.call(this.formdata, key)) {
					this.$data[key] = this.formdata[key];
				}
			}
		}
	},
	validations() {
		return {
			prodName: {
				required,
				minLength: minLength(4),
			},
			manufacturer: {
				minLength: minLength(4),
			},
			prodNo: {
				minLength: minLength(4),
			},
			testimony: {
				// minLength: minLength(4),
			},
			dose: {
				// minLength: minLength(4),
			},
			multiplicity: {
				// minLength: minLength(4),
			},
			drugAdministration: {
				// minLength: minLength(4),
			},
			terapiaStart: {
				dateValidator,
			},
			terapiaEnd: {
				dateValidator,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
</style>