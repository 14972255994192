<template>
	<div class="formExpertComponent">
		<div class="heading">
			<Flickity
				ref="captionsSlider"
				id="expertFormTop"
				:options="captionsSliderOptions"
				class="captionsSlider"
			>
				<div
					class="slide"
					v-for="(item, index) in slides"
					:key="index"
					:class="{ active: index == currentStep }"
				>
					<div class="num">{{ String(index + 1).padStart(2, "0") }}</div>
					<div class="caption">{{ item.label }}</div>
				</div>
			</Flickity>
		</div>
		<div class="stepWrp">
			<form-step-1
				v-if="currentStep == 0"
				@next="onFirstNext"
				:formdata="formData"
			></form-step-1>
			<form-step-2
				v-if="currentStep == 1"
				@back="currentStep = 0"
				@next="onSecondNext"
				:formdata="formData"
			></form-step-2>
			<form-step-3
				v-if="currentStep == 2"
				@back="currentStep = 1"
				@next="onThirdStep"
				:formdata="formData"
			></form-step-3>
			<form-step-4
				v-if="currentStep == 3"
				@back="currentStep = 2"
				@next="onFourStep"
				:formdata="formData"
			></form-step-4>
			<form-step-5
				v-if="currentStep == 4"
				@back="currentStep = 3"
				@next="onFifthStep"
				:formdata="formData"
			></form-step-5>
		</div>

		<modal-base v-if="successModal">
			<form-success @close="successModal = false" />
		</modal-base>
	</div>
</template>

<script>
import Flickity from "vue-flickity";
import FormSuccess from "../Modals/FormSuccess.vue";
import FormStep1 from "./FormSteps/FormStep1.vue";
import FormStep2 from "./FormSteps/FormStep2.vue";
import FormStep3 from "./FormSteps/FormStep3.vue";
import FormStep4 from "./FormSteps/FormStep4.vue";
import FormStep5 from "./FormSteps/FormStep5.vue";
import ModalBase from "./ModalBase.vue";
export default {
	components: {
		Flickity,
		FormStep1,
		FormStep2,
		FormStep3,
		FormStep4,
		FormStep5,
		ModalBase,
		FormSuccess,
	},
	name: "ExpertFeedbackForm",
	data() {
		return {
			currentStep: 0,
			captionsSliderOptions: {
				prevNextButtons: false,
				cellAlign: "left",
				contain: true,
				pageDots: false,
				// groupCells: true,
			},
			slides: [
				{ label: this.$t("form.exp.steps.0") },
				{ label: this.$t("form.exp.steps.1") },
				{ label: this.$t("form.exp.steps.2") },
				{ label: this.$t("form.exp.steps.3") },
				{ label: this.$t("form.exp.steps.4") },
			],

			successModal: false,
			formData: {},
		};
	},
	methods: {
		scrottToUp() {
			let ele = document.getElementById("expertFormTop");
			if (ele) {
				const rect = ele.getBoundingClientRect();
				const top = rect.top + window.scrollY - 30;
				window.scrollTo({
					top: top,
					behavior: "smooth",
				});
			}
		},
		onFirstNext(e) {
			for (const key in e) {
				if (Object.hasOwnProperty.call(e, key)) {
					const element = e[key];
					this.formData[key] = element;
				}
			}
			this.scrottToUp();
			setTimeout(() => {
				this.currentStep = 1;
			}, 500);
		},
		onSecondNext(e) {
			for (const key in e) {
				if (Object.hasOwnProperty.call(e, key)) {
					const element = e[key];
					this.formData[key] = element;
				}
			}
			this.scrottToUp();
			setTimeout(() => {
				this.currentStep = 2;
			}, 500);
		},
		onThirdStep(e) {
			for (const key in e) {
				if (Object.hasOwnProperty.call(e, key)) {
					const element = e[key];
					this.formData[key] = element;
				}
			}
			this.scrottToUp();
			setTimeout(() => {
				this.currentStep = 3;
			}, 500);
		},
		onFourStep(e) {
			for (const key in e) {
				if (Object.hasOwnProperty.call(e, key)) {
					const element = e[key];
					this.formData[key] = element;
				}
			}
			this.scrottToUp();
			setTimeout(() => {
				this.currentStep = 4;
			}, 500);
		},
		onFifthStep(e) {
			for (const key in e) {
				if (Object.hasOwnProperty.call(e, key)) {
					const element = e[key];
					this.formData[key] = element;
				}
			}
			this.axios
				.post("/api/form/specialists", this.formData)
				.then((response) => {
					console.log(response);
					this.successModal = true;
				});
		},
	},
};
</script>

<style lang="scss">
.formExpertComponent{
	@media (max-width: $ts) {
		margin-left: -15px;
		margin-right: -15px;
	}
}
.captionsSlider {
	background-color: $cgray6;
	.flickity-button {
		background-color: $cgray6;
		.arrow {
			fill: $cblacktxt;
		}
	}
	.previous {
		border-right: 1px solid $cgray5;
	}
	.next {
		border-left: 1px solid $cgray5;
	}
	.slide {
		padding: 15px;
		max-width: 180px;
		&.active {
			background-color: transparent;
			position: relative;
			&::before {
				content: "";
				bottom: 0;
				right: 0;
				left: 0;
				height: 3px;
				background-color: $cred1;
				position: absolute;
			}
		}
		.num {
			@include t4;
			color: $cgray3;
		}
		.caption {
			color: $cblacktxt;
			white-space: nowrap;
			@include t4;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-break: normal;
		}
	}
}
.stepWrp {
	background-color: $cgray7;
	padding: 0 40px;
	@media (max-width: $ts) {
		padding: 0 30px;
	}
	@media (max-width: $m) {
		padding: 0 15px;
	}
}

.formStep {
	padding: 60px 0;
	.container {
		max-width: 600px;
		margin: 0 auto;
		.title {
			@include h3;
		}
		// .dateCellWrp {
		// 	display: grid;
		// 	grid-template-columns: 150px 1fr 1fr;
		// 	grid-gap: 10px;
		// 	padding: 20px 0;
		// 	.label {
		// 		font-style: normal;
		// 		font-weight: 500;
		// 		font-size: 14px;
		// 		line-height: 130%;
		// 		letter-spacing: 0.02em;
		// 		color: #585b6d;
		// 	}
		// }
		.centerWideWrp {
			display: grid;
			grid-template-columns: 150px 1fr 180px;
			grid-gap: 10px;
			padding: 20px 0;
			@media (max-width: $tm) {
				display: block !important;
				& > * {
					margin: 16px 0;
				}
			}
			.label {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.02em;
				color: #585b6d;
			}
		}

		.formBtns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;
			button {
				height: 58px;
				display: flex;
				align-items: center;
				border: none;
				background-color: $cgray6;
				cursor: pointer;

				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 120%;
				letter-spacing: 0.03em;
				text-transform: uppercase;
				color: #111325;

				text-transform: uppercase;
				.ic-icarrow-left {
					// margin-left: 20px;
					width: 40px;
					text-align: center;
					font-size: 20px;
				}
				.ic-icarrow-right {
					// margin-right: 20px;
					width: 40px;
					text-align: center;
					font-size: 20px;
				}
				div {
					flex-grow: 1;
				}
				&.send {
					background-color: $cred1;
					color: #fff;
				}
			}
		}
	}
}
.successModalBody {
	width: 100vw;
	max-width: 450px;
	background-color: #fff;
	.closeWrp {
		display: flex;
		justify-content: flex-end;
		.ic-icclose {
			font-size: 18px;
			padding: 9px;
			color: $cgray4;
			cursor: pointer;
		}
	}
	.greenBgTxt {
		background-color: $csuccess;
		text-align: center;
		color: #fff;
		padding: 10px 0;
		.ic-check {
			font-size: 20px;
		}
		.txt {
			@include t2l;
			color: #fff;
		}
	}
	.txtWrp {
		padding: 15px 40px 30px;
		.t1 {
			@include t3l;
		}
		.schedule {
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 160%;
			letter-spacing: -0.025em;
			color: #6cc49f;
		}
		.lnk {
			display: block;
			background-color: $cgray6;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #111325;
			padding: 20px;
			text-decoration: none;
			margin-top: 40px;
		}
	}
}
</style>