<template>
	<div class="checkWro" @click="value = !value">
		<div class="deco" :class="{ active: value }">
			<span class="ic-check"></span>
		</div>
		<div>{{ label }}</div>
	</div>
</template>

<script>
export default {
	name: "Checkbox",
	props: {
		label: {
			type: String,
			default: "placeholder",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: [String, Object],
			default: "",
		},
		prevalue: {
			type: Boolean,
			default: null,
		},
		validate: {
			type: Object,
		},
	},
	data() {
		return {
			value: false,
		};
	},
	watch: {
		value(newValue) {
			this.$emit("change", { name: this.propname, value: newValue });
		},
	},
	mounted() {
		if (this.prevalue) {
			this.value = this.prevalue;
		}
	},
};
</script>

<style lang="scss" scoped>
.checkWro {
	display: flex;
	align-items: center;
	cursor: pointer !important;
	user-select: none;
	margin: 16px 0;
	&.nomargin{
		margin: 0 !important;
	}
	.deco {
		width: 17px;
		height: 17px;
		border: 1px solid #111325;
		border-radius: 1px;
		box-sizing: border-box;
		display: block;
		position: relative;
		transition: 0.3s;
		overflow: hidden;
		margin-right: 10px;

		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #111325;
		.ic-check {
			color: #fff;
			font-size: 13px;
			transition: 0.3s;
			border-radius: 50%;
			position: absolute;
			overflow: hidden;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0;
			height: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #111325;
		}
		&.active {
			border: 1px solid #111325;
			.ic-check {
				width: 150%;
				height: 150%;
			}
		}
	}
}
</style>