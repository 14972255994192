<template>
	<div class="formStep">
		<div class="container">
			<div class="title">{{$t('form.exp.EH4')}}</div>
			<checkbox
				:label="$t('form.exp.t30')"
				:showError="showError"
				@change="onFieldChange"
				:validate="$v.PLZCancel"
				propname="PLZCancel"
				:prevalue="PLZCancel"
			></checkbox>
			<template v-if="PLZCancel == true">
				<radio-input
					:placeholder="$t('form.exp.t31')"
					:items="[$t('form.exp.yn.y'), $t('form.exp.yn.n')]"
					@change="onRadioChange"
					:showError="showError"
					propname="PLZCancelingPR"
					:validate="$v.PLZCancelingPR"
					:prevalue="PLZCancelingPR"
				></radio-input>
			</template>
			<div class="hr"></div>
			<checkbox
				:label="$t('form.exp.t32')"
				:showError="showError"
				@change="onFieldChange"
				:validate="$v.reassignmentPLZ"
				propname="reassignmentPLZ"
				:prevalue="reassignmentPLZ"
			></checkbox>
			<template v-if="reassignmentPLZ == true">
				<radio-input
					:placeholder="$t('form.exp.t33')"
					:items="[$t('form.exp.yn.y'), $t('form.exp.yn.n')]"
					@change="onRadioChange"
					:showError="showError"
					propname="PRRecoverAfterPLZ"
					:validate="$v.PRRecoverAfterPLZ"
					:prevalue="PRRecoverAfterPLZ"
				></radio-input>
			</template>
			<div class="hr"></div>
			<checkbox
				:label="$t('form.exp.t34')"
				:showError="showError"
				@change="onFieldChange"
				:validate="$v.changingTheDoseRegimenOfPLZ"
				propname="changingTheDoseRegimenOfPLZ"
				:prevalue="changingTheDoseRegimenOfPLZ"
			></checkbox>
			<textarea-input
				:placeholder="$t('form.exp.t35')"
				@change="onFieldChange"
				:showError="showError"
				propname="changingTheDoseRegimenOfPLZtext"
				:validate="$v.changingTheDoseRegimenOfPLZtext"
				:prevalue="changingTheDoseRegimenOfPLZtext"
			></textarea-input>
			<div class="hr"></div>
			<radio-input
				:placeholder="$t('form.exp.t36')"
				:items="[$t('form.exp.t36i.t1'), $t('form.exp.t36i.t2')]"
				@change="onRadioChange"
				:showError="showError"
				propname="PR_VE"
				:validate="$v.PR_VE"
				:prevalue="PR_VE"
				:onrow="true"
			></radio-input>
			<textarea-input
				:placeholder="$t('form.exp.t37')"
				@change="onFieldChange"
				:showError="showError"
				propname="additionInformation"
				:validate="$v.additionInformation"
				:prevalue="additionInformation"
			></textarea-input>

			<div class="formBtns">
				<button class="back" @click="$emit('back')">
					<span class="ic-icarrow-left"></span>
					<div>{{ $t("formActions.back") }}</div>
				</button>
				<button class="next" @click="onNext">
					<div>{{ $t("formActions.next") }}</div>
					<span class="ic-icarrow-right"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
// import { required, minLength } from "vuelidate/lib/validators";
import DatePickerField from "../../Inputs/DatePickerField.vue";
import FormInput from "../../Inputs/FormInput.vue";
// import { dateValidator } from "@/CustomValidators.js";
import Checkbox from "../../Inputs/Checkbox.vue";
import RadioInput from "../../Inputs/RadioInput.vue";
import TextareaInput from "../../Inputs/TextareaInput.vue";
export default {
	components: {
		FormInput,
		DatePickerField,
		Checkbox,
		RadioInput,
		TextareaInput,
	},
	name: "ExpertFormStep2",
	props: {
		formdata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			showError: false,

			PLZCancel: false,
			PLZCancelingPR: "",
			reassignmentPLZ: false,
			PRRecoverAfterPLZ: "",
			changingTheDoseRegimenOfPLZ: false,
			changingTheDoseRegimenOfPLZtext: "",
			PR_VE: "",
			additionInformation: "",
		};
	},
	methods: {
		onNext() {
			this.showError = true;
			// if (!this.$v.$invalid) {
			this.$emit("next", {
				PLZCancel: this.PLZCancel,
				PLZCancelingPR: this.PLZCancelingPR,
				reassignmentPLZ: this.reassignmentPLZ,
				PRRecoverAfterPLZ: this.PRRecoverAfterPLZ,
				changingTheDoseRegimenOfPLZ: this.changingTheDoseRegimenOfPLZ,
				changingTheDoseRegimenOfPLZtext: this.changingTheDoseRegimenOfPLZtext,
				PR_VE: this.PR_VE,
				additionInformation: this.additionInformation,
			});
			// }
		},
	},
	mounted () {
		for (const key in this.$data) {
			if (Object.hasOwnProperty.call(this.$data, key)) {
				if (Object.hasOwnProperty.call(this.formdata, key)) {
					this.$data[key] = this.formdata[key];
				}
			}
		}
	},
	validations() {
		return {
			PLZCancel: {},
			PLZCancelingPR: {},
			reassignmentPLZ: {},
			PRRecoverAfterPLZ: {},
			changingTheDoseRegimenOfPLZtext: {},
			PR_VE: {},
			additionInformation: {},
		};
	},
};
</script>

<style lang="scss" scoped>
</style>