var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formStep"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('form.exp.EH3')))]),_vm._l((_vm.items),function(item,index){return [_c('div',{key:index},[_c('div',{staticClass:"defText"},[_vm._v(_vm._s(_vm.$t('form.exp.t28')))]),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t29'),"propname":{ name: 'prodName', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.prodName},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t16'),"propname":{ name: 'manufacturer', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.manufacturer},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t17'),"propname":{ name: 'prodNo', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.prodNo},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t18'),"propname":{ name: 'testimony', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.testimony},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t19'),"propname":{ name: 'dose', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.dose},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t20'),"propname":{ name: 'multiplicity', index: index, array: 'items' },"showError":_vm.showError,"prevalue":item.multiplicity},on:{"change":_vm.onFieldChange}}),_c('form-input',{attrs:{"placeholder":_vm.$t('form.exp.t21'),"propname":{
							name: 'drugAdministration',
							index: index,
							array: 'items',
						},"showError":_vm.showError,"prevalue":item.drugAdministration},on:{"change":_vm.onFieldChange}}),_c('div',{staticClass:"centerWideWrp"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('form.exp.t22')))]),_c('date-picker-field',{attrs:{"placeholder":_vm.$t('form.exp.t23'),"showError":_vm.showError,"propname":{ name: 'terapiaStart', index: index, array: 'items' },"prevalue":item.terapiaStart},on:{"change":_vm.onFieldChange}}),_c('checkbox',{staticClass:"nomargin",attrs:{"label":_vm.$t('form.exp.t24'),"showError":_vm.showError,"propname":{
								name: 'terapiaStartUndefined',
								index: index,
								array: 'items',
							},"prevalue":item.terapiaStartUndefined},on:{"change":_vm.onFieldChange}})],1),_c('div',{staticClass:"centerWideWrp"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('form.exp.t25')))]),_c('date-picker-field',{attrs:{"placeholder":_vm.$t('form.exp.t26'),"showError":_vm.showError,"propname":{ name: 'terapiaEnd', index: index, array: 'items' },"prevalue":item.terapiaEnd},on:{"change":_vm.onFieldChange}}),_c('checkbox',{staticClass:"nomargin",attrs:{"label":_vm.$t('form.exp.t27'),"showError":_vm.showError,"propname":{
								name: 'terapiaEndUndefined',
								index: index,
								array: 'items',
							},"prevalue":item.terapiaEndUndefined},on:{"change":_vm.onFieldChange}})],1)],1)]}),_c('div',{staticClass:"appendItem",on:{"click":_vm.addItem}},[_vm._v(" "+_vm._s(_vm.$t("form.addProdItem"))+" ")]),_c('div',{staticClass:"formBtns"},[_c('button',{staticClass:"back",on:{"click":function($event){return _vm.$emit('back')}}},[_c('span',{staticClass:"ic-icarrow-left"}),_c('div',[_vm._v(_vm._s(_vm.$t("formActions.back")))])]),_c('button',{staticClass:"next",on:{"click":_vm.onNext}},[_c('div',[_vm._v(_vm._s(_vm.$t("formActions.next")))]),_c('span',{staticClass:"ic-icarrow-right"})])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }