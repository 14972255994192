<template>
	<main>
		<section class="expertFeedbackView">
			<div class="container">
				<div class="leftsidebar">
					<div class="breadCrumbsWrp">
						<bread-crumbs
							:items="bc"
							:last="true"
						></bread-crumbs>
					</div>
				</div>
				<div class="content">
					<div v-html="$store.state.form_pharmakanyaglyad_default_text1"></div>
					<div v-html="$store.state.form_pharmakanyaglyad_default_text2"></div>
					<div
						class="red"
						v-html="$store.state.form_pharmakanyaglyad_default_text3"
					></div>
					<user-feedback-form></user-feedback-form>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import UserFeedbackForm from "../components/Parts/UserFeedbackForm.vue";
export default {
	components: { BreadCrumbs, UserFeedbackForm },
	name: "ExpertFeedbackView",
    computed: {
        bc() {
            if (this.locale == "uk")
                return [
                    { text: 'Головна', url: '/' },
					{ text: 'Фармаконагляд', url: '/farmakonaglyad' },
                ];
            else if ( this.locale == "ru" ) {
                return [
                    { text: "Главная", url: "/ru" },
                    { text: "Фармаконагляд", url: "/ru/farmakonaglyad" }
                ];
            } else {
                return [
                    { text: "Home", url: "/en" },
                    { text: "Pharmacovigilance", url: "/en/farmakonaglyad" }
                ];
            }

        }
    },
};
</script>

<style lang="scss">
.content {
	h1 {
		@include h2mob;
	}
	& > p {
		@include t3l;
	}
	.red {
		color: $cred1;
		margin: 16px 0;
	}
}
</style>
<style lang="scss" scoped>
.expertFeedbackView {
	padding-bottom: 60px;
	.container {
		@include container;
		display: grid;
		grid-template-columns: 1fr 3fr;
		@media (max-width: $tl) {
			grid-template-columns: 1fr;
		}
		.leftsidebar {
			margin-right: 50px;
			border-right: 1px solid $cgray5;
			@media (max-width: $tl) {
				margin-right: 0;
				border: none;
			}
			.breadCrumbsWrp {
				border-bottom: 1px solid $cgray5;
				padding-bottom: 48px;
				@media (max-width: $tl) {
					padding-bottom: 24px;
				}
			}
		}
	}
}
</style>
