<template>
	<div class="formStep">
		<div class="container">
			<div class="title">{{ $t("form.exp.EH5") }}</div>
			<form-input
				:placeholder="$t('form.exp.t38')"
				@change="onFieldChange"
				:showError="showError"
				propname="informerName"
				:validate="$v.informerName"
				:prevalue="informerName"
			></form-input>
			<phone-input
				:placeholder="$t('form.exp.t39')"
				@change="onPhoneChange"
				:showError="showError"
				propname="informerPhoneFax"
				:validate="$v.informerPhoneFax"
				:prevalue="informerPhoneFax"
			></phone-input>
			<form-input
				:placeholder="'E-mail'"
				@change="onFieldChange"
				:showError="showError"
				propname="informerEmail"
				:validate="$v.informerEmail"
				:prevalue="informerEmail"
			></form-input>
			<textarea-input
				:placeholder="$t('form.exp.t40')"
				@change="onFieldChange"
				:showError="showError"
				propname="informerClinicName"
				:validate="$v.informerClinicName"
				:prevalue="informerClinicName"
			></textarea-input>
			<radio-input
				:placeholder="$t('form.exp.t41')"
				:items="[
					$t('form.exp.t41t.t1'),
					$t('form.exp.t41t.t2'),
					$t('form.exp.t41t.t3'),
					$t('form.exp.t41t.t4'),
					$t('form.exp.t41t.t5'),
					$t('form.exp.t41t.t6'),
				]"
				@change="onRadioChange"
				:showError="showError"
				propname="informerReporter"
				:validate="$v.informerReporter"
				:prevalue="informerReporter"
				:onrow="true"
			></radio-input>

			<div class="formBtns">
				<button class="back" @click="$emit('back')">
					<span class="ic-icarrow-left"></span>
					<div>{{ $t("formActions.back") }}</div>
				</button>
				<button class="send" @click="onNext">
					<div>{{ $t("formActions.send") }}</div>
					<span class="ic-icarrow-right"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import DatePickerField from "../../Inputs/DatePickerField.vue";
import FormInput from "../../Inputs/FormInput.vue";
// import { dateValidator } from "@/CustomValidators.js";
import Checkbox from "../../Inputs/Checkbox.vue";
import RadioInput from "../../Inputs/RadioInput.vue";
import TextareaInput from "../../Inputs/TextareaInput.vue";
import PhoneInput from "../../Inputs/PhoneInput.vue";
export default {
	components: {
		FormInput,
		DatePickerField,
		Checkbox,
		RadioInput,
		TextareaInput,
		PhoneInput,
	},
	props: {
		formdata: {
			type: Object,
			default: null,
		},
	},
	name: "ExpertFormStep2",
	data() {
		return {
			showError: false,

			informerName: "",
			informerPhoneFax: "",
			informerEmail: "",
			informerClinicName: "",
			informerReporter: "",

			phoneLength: 0,
		};
	},
	methods: {
		onNext() {
			this.showError = true;
			if (!this.$v.$invalid) {
				this.$emit("next", {
					informerName: this.informerName,
					informerPhoneFax: this.informerPhoneFax,
					informerEmail: this.informerEmail,
					informerClinicName: this.informerClinicName,
					informerReporter: this.informerReporter,
				});
			}
		},
	},
	mounted() {
		for (const key in this.$data) {
			if (Object.hasOwnProperty.call(this.$data, key)) {
				if (Object.hasOwnProperty.call(this.formdata, key)) {
					this.$data[key] = this.formdata[key];
				}
			}
		}
	},
	validations() {
		return {
			informerName: {
				required,
				minLength: minLength(4),
			},
			informerPhoneFax: {
				required,
				minLength: minLength(this.phoneLength),
			},
			informerEmail: {},
			informerClinicName: {},
			informerReporter: {},
		};
	},
};
</script>

<style lang="scss" scoped>
</style>