<template>
	<div class="formUserComponent">
		<div class="stepWrp">
			<div class="formStep">
				<div class="container">
					<div class="title">{{ $t("form.cons.EH1") }}</div>
					<form-input
						:placeholder="$t('form.cons.t1')"
						@change="onFieldChange"
						:showError="showError"
						propname="prodName"
						:validate="$v.prodName"
						:prevalue="prodName"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t2')"
						@change="onFieldChange"
						:showError="showError"
						propname="ambulanceForm"
						:validate="$v.ambulanceForm"
						:prevalue="ambulanceForm"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t3')"
						@change="onFieldChange"
						:showError="showError"
						propname="manufacturer"
						:validate="$v.manufacturer"
						:prevalue="manufacturer"
					></form-input>

					<div class="title">
						{{ $t("form.cons.EH2") }}
					</div>
					<textarea-input
						:placeholder="$t('form.cons.t4')"
						@change="onFieldChange"
						:showError="showError"
						propname="PRDetail"
						:validate="$v.PRDetail"
						:prevalue="PRDetail"
					></textarea-input>

					<div class="title">{{ $t("form.cons.EH3") }}</div>
					<form-input
						:placeholder="$t('form.cons.t5')"
						@change="onFieldChange"
						:showError="showError"
						propname="userName"
						:validate="$v.userName"
						:prevalue="userName"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t6')"
						@change="onFieldChange"
						:showError="showError"
						propname="adres"
						:validate="$v.adres"
						:prevalue="adres"
					></form-input>
					<phone-input
						:placeholder="$t('form.cons.t7')"
						@change="onPhoneChange"
						:showError="showError"
						propname="userPhone"
						:validate="$v.userPhone"
						:prevalue="userPhone"
						lengthname="phoneLength"
					></phone-input>

					<div class="title">{{ $t("form.cons.EH4") }}</div>
					<form-input
						:placeholder="$t('form.cons.t8')"
						@change="onFieldChange"
						:showError="showError"
						propname="pacientName"
						:validate="$v.pacientName"
						:prevalue="pacientName"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t9')"
						@change="onFieldChange"
						:showError="showError"
						propname="age"
						:validate="$v.age"
						:prevalue="age"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t10')"
						@change="onFieldChange"
						:showError="showError"
						propname="weight"
						:validate="$v.weight"
						:prevalue="weight"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t11')"
						@change="onFieldChange"
						:showError="showError"
						propname="diagnosis"
						:validate="$v.diagnosis"
						:prevalue="diagnosis"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t6')"
						@change="onFieldChange"
						:showError="showError"
						propname="pacientAdres"
						:validate="$v.pacientAdres"
						:prevalue="pacientAdres"
					></form-input>
					<radio-input
						:placeholder="$t('form.cons.t12')"
						:items="[$t('form.exp.gender.male'), $t('form.exp.gender.female')]"
						@change="onRadioChange"
						:showError="showError"
						propname="gender"
						:validate="$v.gender"
						:prevalue="gender"
					></radio-input>
					<phone-input
						:placeholder="$t('form.cons.t13')"
						@change="onPhoneChange"
						:showError="showError"
						propname="pacientPhone"
						:validate="$v.pacientPhone"
						:prevalue="pacientPhone"
						lengthname="pacientPhoneLength"
					></phone-input>

					<div class="title">
						{{ $t("form.cons.EH5") }}
					</div>
					<radio-input
						:placeholder="$t('form.cons.t14')"
						:items="[$t('form.exp.yn.y'), $t('form.exp.yn.n')]"
						@change="onRadioChange"
						:showError="showError"
						propname="doctorPrescribed"
						:validate="$v.doctorPrescribed"
						:prevalue="doctorPrescribed"
					></radio-input>
					<radio-input
						:placeholder="$t('form.cons.t15')"
						:items="[$t('form.exp.yn.y'), $t('form.exp.yn.n')]"
						@change="onRadioChange"
						:showError="showError"
						propname="patientUsedDrugWithoutPrescription"
						:validate="$v.patientUsedDrugWithoutPrescription"
						:prevalue="patientUsedDrugWithoutPrescription"
					></radio-input>

					<div class="title">
						{{ $t("form.cons.EH6") }}
					</div>
					<form-input
						:placeholder="$t('form.cons.t16')"
						@change="onFieldChange"
						:showError="showError"
						propname="doctorName"
						:validate="$v.doctorName"
						:prevalue="doctorName"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t17')"
						@change="onFieldChange"
						:showError="showError"
						propname="healthCareInstitutionName"
						:validate="$v.healthCareInstitutionName"
						:prevalue="healthCareInstitutionName"
					></form-input>
					<form-input
						:placeholder="$t('form.cons.t18')"
						@change="onFieldChange"
						:showError="showError"
						propname="healthCareInstitutionAddress"
						:validate="$v.healthCareInstitutionAddress"
						:prevalue="healthCareInstitutionAddress"
					></form-input>
					<phone-input
						:placeholder="$t('form.cons.t19')"
						@change="onPhoneChange"
						:showError="showError"
						propname="healthCareInstitutionPhone"
						:validate="$v.healthCareInstitutionPhone"
						:prevalue="healthCareInstitutionPhone"
						lengthname="healthCareInstitutionPhoneLength"
					></phone-input>
					<div class="formBtns single">
						<button class="send" @click="onSend">
							<div>{{ $t("formActions.send") }}</div>
							<span class="ic-icarrow-right"></span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<modal-base v-if="successModal">
			<form-success @close="successModal = false" />
		</modal-base>
	</div>
</template>

<script>
import FormInput from "../Inputs/FormInput.vue";
import ModalBase from "./ModalBase.vue";
import { required, minLength } from "vuelidate/lib/validators";
import TextareaInput from "../Inputs/TextareaInput.vue";
import PhoneInput from "../Inputs/PhoneInput.vue";
import RadioInput from "../Inputs/RadioInput.vue";
import FormSuccess from "../Modals/FormSuccess.vue";
export default {
	components: {
		ModalBase,
		FormInput,
		TextareaInput,
		PhoneInput,
		RadioInput,
		FormSuccess,
	},
	name: "UserFeedbackForm",
	data() {
		return {
			successModal: false,
			showError: false,
			phoneLength: 0,
			// patientPhoneLength: 0,
			pacientPhoneLength: 0,
			healthCareInstitutionPhoneLength: 0,

			prodName: "",
			ambulanceForm: "",
			manufacturer: "",
			PRDetail: "",
			userName: "",
			adres: "",
			// userEmail: "",
			userPhone: "",
			pacientName: "",
			age: "",
			weight: "",
			diagnosis: "",
			pacientAdres: "",
			gender: "",
			pacientPhone: "",
			doctorPrescribed: "",
			patientUsedDrugWithoutPrescription: "",
			doctorName: "",
			healthCareInstitutionName: "",
			healthCareInstitutionAddress: "",
			healthCareInstitutionPhone: "",
		};
	},
	methods: {
		onSend() {
			this.showError = true;
			if (!this.$v.$invalid) {
				let a = {
					prodName: this.prodName,
					ambulanceForm: this.ambulanceForm,
					manufacturer: this.manufacturer,
					PRDetail: this.PRDetail,
					userName: this.userName,
					adres: this.adres,
					userPhone: this.userPhone,
					pacientName: this.pacientName,
					age: this.age,
					weight: this.weight,
					diagnosis: this.diagnosis,
					pacientAdres: this.pacientAdres,
					gender: this.gender,
					pacientPhone: this.pacientPhone,
					doctorPrescribed: this.doctorPrescribed,
					patientUsedDrugWithoutPrescription:
						this.patientUsedDrugWithoutPrescription,
					doctorName: this.doctorName,
					healthCareInstitutionName: this.healthCareInstitutionName,
					healthCareInstitutionAddress: this.healthCareInstitutionAddress,
					healthCareInstitutionPhone: this.healthCareInstitutionPhone,
				};
				this.axios.post("/api/form/consumers", a).then((response) => {
					console.log(response);
					this.successModal = true;
				});
			}
		},
	},
	validations() {
		return {
			prodName: {
				required,
				minLength: minLength(4),
			},
			ambulanceForm: {},
			manufacturer: {
				required,
				minLength: minLength(4),
			},
			PRDetail: {},
			userName: {
				required,
				minLength: minLength(4),
			},
			adres: {},
			userPhone: {
				required,
				minLength: minLength(this.phoneLength),
			},
			pacientName: {
				required,
				minLength: minLength(4),
			},
			age: {},
			weight: {},
			diagnosis: {},
			pacientAdres: {},
			gender: {},
			pacientPhone: {
				minLength: minLength(this.pacientPhoneLength),
			},
			doctorPrescribed: {},
			patientUsedDrugWithoutPrescription: {},
			doctorName: {},
			healthCareInstitutionName: {},
			healthCareInstitutionAddress: {},
			healthCareInstitutionPhone: {
				minLength: minLength(this.healthCareInstitutionPhoneLength),
			},
		};
	},
};
</script>

<style lang="scss">
.formUserComponent {
	@media (max-width: $ts) {
		margin-left: -15px;
		margin-right: -15px;
	}
}
.captionsSlider {
	background-color: $cgray6;
	.flickity-button {
		background-color: $cgray6;
		.arrow {
			fill: $cblacktxt;
		}
	}
	.previous {
		border-right: 1px solid $cgray5;
	}
	.next {
		border-left: 1px solid $cgray5;
	}
	.slide {
		padding: 15px;
		&.active {
			background-color: transparent;
			position: relative;
			&::before {
				content: "";
				bottom: 0;
				right: 0;
				left: 0;
				height: 3px;
				background-color: $cred1;
				position: absolute;
			}
		}
		.num {
			@include t4;
			color: $cgray3;
		}
		.caption {
			color: $cblacktxt;
			white-space: nowrap;
			@include t4;
		}
	}
}
.stepWrp {
	background-color: $cgray7;
	padding: 0 40px;
	@media (max-width: $ts) {
		padding: 0 30px;
	}
	@media (max-width: $m) {
		padding: 0 15px;
	}
}

.formStep {
	padding: 60px 0;
	.container {
		max-width: 600px;
		margin: 0 auto;
		.title {
			@include h3;
		}
		* + .title {
			margin-top: 80px;
		}
		// .dateCellWrp {
		// 	display: grid;
		// 	grid-template-columns: 150px 1fr 1fr;
		// 	grid-gap: 10px;
		// 	padding: 20px 0;
		// 	.label {
		// 		font-style: normal;
		// 		font-weight: 500;
		// 		font-size: 14px;
		// 		line-height: 130%;
		// 		letter-spacing: 0.02em;
		// 		color: #585b6d;
		// 	}
		// }
		.centerWideWrp {
			display: grid;
			grid-template-columns: 150px 1fr 180px;
			grid-gap: 10px;
			padding: 20px 0;
			.label {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.02em;
				color: #585b6d;
			}
		}

		.formBtns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;
			&.single {
				grid-template-columns: 1fr;
			}
			button {
				height: 58px;
				display: flex;
				align-items: center;
				border: none;
				background-color: $cgray6;
				cursor: pointer;

				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 120%;
				letter-spacing: 0.03em;
				text-transform: uppercase;
				color: #111325;

				text-transform: uppercase;
				.ic-icarrow-left {
					// margin-left: 20px;
					width: 40px;
					text-align: center;
					font-size: 20px;
				}
				.ic-icarrow-right {
					// margin-right: 20px;
					width: 40px;
					text-align: center;
					font-size: 20px;
				}
				div {
					flex-grow: 1;
				}
				&.send {
					background-color: $cred1;
					color: #fff;
				}
			}
		}
	}
}
</style>