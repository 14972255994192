<template>
	<div class="formStep">
		<div class="container">
			<div class="title">{{ $t("form.exp.EH1") }}</div>
			<form-input
				:placeholder="$t('form.exp.t1')"
				@change="onFieldChange"
				propname="name"
				:validate="$v.name"
				:showError="showError"
				:prevalue="name"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t2')"
				@change="onFieldChange"
				propname="date"
				:validate="$v.date"
				:showError="showError"
				:prevalue="date"
			></form-input>
			<form-input
				:placeholder="$t('form.exp.t3')"
				@change="onFieldChange"
				propname="illNo"
				:validate="$v.illNo"
				:showError="showError"
				:prevalue="illNo"
			></form-input>
			<radio-input
				:placeholder="$t('form.exp.t4')"
				:items="[$t('form.exp.gender.male'), $t('form.exp.gender.female')]"
				@change="onRadioChange"
				propname="gender"
				:validate="$v.gender"
				:showError="showError"
				:prevalue="gender"
			></radio-input>
			<form-input
				:placeholder="$t('form.exp.t6')"
				@change="onFieldChange"
				propname="weight"
				:validate="$v.weight"
				:showError="showError"
				:prevalue="weight"
			></form-input>
			<div class="dateCellWrp">
				<label class="label">{{ $t("form.exp.t5") }}</label>
				<date-picker
					:placeholder="$t('form.exp.t7')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.startDate"
					propname="startDate"
					:prevalue="startDate"
				></date-picker>
				<time-picker
					:placeholder="$t('form.exp.t8')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.startTime"
					propname="startTime"
					:prevalue="startTime"
				></time-picker>
			</div>
			<div class="dateCellWrp">
				<label class="label">{{ $t("form.exp.t9") }}</label>
				<date-picker
					:placeholder="$t('form.exp.t10')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.endDate"
					propname="endDate"
					:prevalue="endDate"
				></date-picker>
				<time-picker
					:placeholder="$t('form.exp.t11')"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.endTime"
					propname="endTime"
					:prevalue="endTime"
				></time-picker>
			</div>
			<textarea-input
				:placeholder="$t('form.exp.t12')"
				:description="$t('form.exp.t12d')"
				@change="onFieldChange"
				propname="description"
				:validate="$v.description"
				:showError="showError"
				:prevalue="description"
			></textarea-input>

			<radio-input
				:placeholder="$t('form.exp.t13')"
				:items="naslidkiItems"
				@change="onRadioChange"
				propname="naslidki"
				:validate="$v.naslidki"
				:showError="showError"
				:prevalue="naslidki"
				:prevalueExtra="naslidkiExtra"
				:validateExtra="$v.naslidkiExtra"
			></radio-input>
			<radio-input
				:placeholder="$t('form.exp.t14')"
				:items="categoriesPrItems"
				@change="onRadioChange"
				propname="categoriesPr"
				:validate="$v.categoriesPr"
				:showError="showError"
				:prevalue="categoriesPr"
				:prevalueExtra="categoriesPrExtra"
				:validateExtra="$v.categoriesPrExtra"
			></radio-input>
			<div class="formBtns">
				<!-- <button class="back" disabled @click="$emit('back')">
					<span class="ic-icarrow-left"></span>
					<div>{{ $t("formActions.back") }}</div>
				</button> -->
				<button class="next" @click="onNext">
					<div>{{ $t("formActions.next") }}</div>
					<span class="ic-icarrow-right"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import FormInput from "../../Inputs/FormInput.vue";
import { required, minLength } from "vuelidate/lib/validators";
import RadioInput from "../../Inputs/RadioInput.vue";
import DatePicker from "../../Inputs/DatePickerField.vue";
import TimePicker from "../../Inputs/TimePicker.vue";
import TextareaInput from "../../Inputs/TextareaInput.vue";
const naslidkiExtraValidator = (value, vm) => {
	let a = vm.naslidkiItems.find(
		(item) => item == vm.naslidki || item.label == vm.naslidki
	);
	if (typeof a == "string") return true;
	else {
		return value != null && typeof value == "object";
	}
};
import { dateValidator } from "@/CustomValidators.js";
import { timeValidator } from "@/CustomValidators.js";
const categoriesPrExtraValidator = (value, vm) => {
	let a = vm.categoriesPrItems.find(
		(item) => item == vm.categoriesPr || item.label == vm.categoriesPr
	);
	if (typeof a == "string") return true;
	else {
		return typeof value == "object";
	}
};
export default {
	components: { FormInput, RadioInput, DatePicker, TimePicker, TextareaInput },
	name: "ExpertFormStep1",
	props: {
		disableBack: {
			type: Boolean,
			default: false,
		},
		formdata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			showError: false,

			name: "",
			date: "",
			illNo: "",
			gender: "",
			weight: "",
			description: "",
			startDate: "",
			startTime: "",
			endDate: "",
			endTime: "",
			naslidki: "",
			naslidkiExtra: "",
			categoriesPr: "",
			categoriesPrExtra: "",

			phoneLength: 0,
		};
	},
	computed: {
		naslidkiItems() {
			return [
				this.$t("form.exp.naslidki.0"),
				this.$t("form.exp.naslidki.1"),
				this.$t("form.exp.naslidki.2"),
				this.$t("form.exp.naslidki.3"),
				this.$t("form.exp.naslidki.4"),
				{
					label: this.$t("form.exp.naslidki.5"),
					extra: "date",
				},
				{
					label: this.$t("form.exp.naslidki.6"),
					extra: "date",
				},
				{
					label: this.$t("form.exp.naslidki.7"),
					extra: "date",
				},
			];
		},
		categoriesPrItems() {
			return [
				{
					label: this.$t("form.exp.categoriespr.0"),
					extra: "date",
				},
				this.$t("form.exp.categoriespr.1"),
				this.$t("form.exp.categoriespr.2"),
				this.$t("form.exp.categoriespr.3"),
				this.$t("form.exp.categoriespr.4"),
				this.$t("form.exp.categoriespr.5"),
				this.$t("form.exp.categoriespr.6"),
				this.$t("form.exp.categoriespr.7"),
			];
		},
	},
	methods: {
		onNext() {
			this.showError = true;
			if (!this.$v.$invalid) {
				this.$emit("next", {
					name: this.name,
					date: this.date,
					illNo: this.illNo,
					gender: this.gender,
					weight: this.weight,
					description: this.description,
					startDate: this.startDate,
					startTime: this.startTime,
					naslidki: this.naslidki,
					naslidkiExtra: this.naslidkiExtra,
					categoriesPr: this.categoriesPr,
					categoriesPrExtra: this.categoriesPrExtra,
				});
			}
		},
	},
	mounted() {
		for (const key in this.$data) {
			if (Object.hasOwnProperty.call(this.$data, key)) {
				if (Object.hasOwnProperty.call(this.formdata, key)) {
					this.$data[key] = this.formdata[key];
				}
			}
		}
	},
	created() {},
	validations() {
		return {
			name: {
				required,
				minLength: minLength(4),
			},
			date: {},
			illNo: {},
			gender: {},
			weight: {
				minLength: minLength(2),
			},
			description: {
				required,
			},
			startDate: {
				dateValidator,
			},
			startTime: {
				timeValidator,
			},
			endDate: {
				dateValidator,
			},
			endTime: {
				timeValidator,
			},
			naslidki: {
				required,
			},
			naslidkiExtra: {
				naslidkiExtraValidator,
			},
			categoriesPr: {
				required,
			},
			categoriesPrExtra: {
				categoriesPrExtraValidator,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
</style>