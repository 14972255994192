<template>
	<div class="formStep">
		<div class="container">
			<div class="title">{{$t('form.exp.EH3')}}</div>
			<template v-for="(item, index) in items">
				<div :key="index">
                    <!-- {{ index + 1 }} -->
					<div class="defText">{{$t('form.exp.t28')}}</div>
					<form-input
						:placeholder="$t('form.exp.t29')"
						@change="onFieldChange"
						:propname="{ name: 'prodName', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.prodName"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t16')"
						@change="onFieldChange"
						:propname="{ name: 'manufacturer', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.manufacturer"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t17')"
						@change="onFieldChange"
						:propname="{ name: 'prodNo', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.prodNo"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t18')"
						@change="onFieldChange"
						:propname="{ name: 'testimony', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.testimony"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t19')"
						@change="onFieldChange"
						:propname="{ name: 'dose', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.dose"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t20')"
						@change="onFieldChange"
						:propname="{ name: 'multiplicity', index: index, array: 'items' }"
						:showError="showError"
						:prevalue="item.multiplicity"
					></form-input>
					<form-input
						:placeholder="$t('form.exp.t21')"
						@change="onFieldChange"
						:propname="{
							name: 'drugAdministration',
							index: index,
							array: 'items',
						}"
						:showError="showError"
						:prevalue="item.drugAdministration"
					></form-input>
					<div class="centerWideWrp">
						<label class="label">{{$t('form.exp.t22')}}</label>
						<date-picker-field
							:placeholder="$t('form.exp.t23')"
							:showError="showError"
							@change="onFieldChange"
							:propname="{ name: 'terapiaStart', index: index, array: 'items' }"
							:prevalue="item.terapiaStart"
						></date-picker-field>
						<checkbox
							class="nomargin"
							:label="$t('form.exp.t24')"
							:showError="showError"
							@change="onFieldChange"
							:propname="{
								name: 'terapiaStartUndefined',
								index: index,
								array: 'items',
							}"
							:prevalue="item.terapiaStartUndefined"
						></checkbox>
					</div>
					<div class="centerWideWrp">
						<label class="label">{{$t('form.exp.t25')}}</label>
						<date-picker-field
							:placeholder="$t('form.exp.t26')"
							:showError="showError"
							@change="onFieldChange"
							:propname="{ name: 'terapiaEnd', index: index, array: 'items' }"
							:prevalue="item.terapiaEnd"
						></date-picker-field>
						<checkbox
							class="nomargin"
							:label="$t('form.exp.t27')"
							:showError="showError"
							@change="onFieldChange"
							:propname="{
								name: 'terapiaEndUndefined',
								index: index,
								array: 'items',
							}"
							:prevalue="item.terapiaEndUndefined"
						></checkbox>
					</div>
				</div>
			</template>
			<div class="appendItem" @click="addItem">
				{{ $t("form.addProdItem") }}
			</div>
			<div class="formBtns">
				<button class="back" @click="$emit('back')">
					<span class="ic-icarrow-left"></span>
					<div>{{ $t("formActions.back") }}</div>
				</button>
				<button class="next" @click="onNext">
					<div>{{ $t("formActions.next") }}</div>
					<span class="ic-icarrow-right"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import DatePickerField from "../../Inputs/DatePickerField.vue";
import FormInput from "../../Inputs/FormInput.vue";
import Checkbox from "../../Inputs/Checkbox.vue";
export default {
	components: { FormInput, DatePickerField, Checkbox },
	name: "ExpertFormStep2",
	props: {
		formdata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			showError: false,

			items: [
				{
					prodName: "",
					manufacturer: "",
					prodNo: "",
					testimony: "",
					dose: "",
					multiplicity: "",
					drugAdministration: "",

					terapiaStart: "",
					terapiaStartUndefined: false,
					terapiaEnd: "",
					terapiaEndUndefined: false,
				},
			],
		};
	},
	methods: {
		onNext() {
			this.showError = true;
			this.$emit("next", {
				prodItems: this.items,
			});
		},
		addItem() {
			this.items.push({
				prodName: "",
				manufacturer: "",
				prodNo: "",
				testimony: "",
				dose: "",
				multiplicity: "",
				drugAdministration: "",

				terapiaStart: "",
				terapiaStartUndefined: false,
				terapiaEnd: "",
				terapiaEndUndefined: false,
			});
		},
	},
	mounted () {
		for (const key in this.$data) {
			if (Object.hasOwnProperty.call(this.$data, key)) {
				if (Object.hasOwnProperty.call(this.formdata, key)) {
					this.$data[key] = this.formdata[key];
				}
			}
		}
	},
	validations() {
	},
};
</script>

<style lang="scss" scoped>
.defText {
	@include h6;
	margin-top: 55px;
}
.appendItem {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	font-feature-settings: "cpsp" on;
	color: #111325;
	padding: 20px;
	border: 1px solid #c7c8d3;
	box-sizing: border-box;
	text-align: center;
	margin-bottom: 60px;
	margin-top: 60px;
	cursor: pointer;
}
</style>
